import React from 'react'
import { Link } from 'react-router-dom'
import {
  posts_url,
  information_page_url,
} from "../config";

import '../stylesheets/pages/top.scss';

import Loading from '../components/Loading'
import RenderPost from '../components/RenderPost'

import topBgImage from "../images/top-bg.jpg";
import snsBgImage from "../images/top-bg.jpg";
// import snsBgImage from "../images/sns-bg.png";

//indexページのコンポーネント
class Top extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
      informationData: {}
    };
  }

  componentDidMount() {
    // タイトル・ディスクリプション
    document.title = 'Bar sunnyspot';
    document.getElementsByName('description')[0].content = '柏駅 東口 「敷居の低い創作カクテルバー」';

    // Promise でデータを取得
    fetch(posts_url)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          data: responseJson,
        });

        // Promise でデータを取得
        fetch(information_page_url)
          .then((response) => response.json())
          .then((responseJson) => {
            this.setState({
              loading: true,
              informationData: responseJson,
            });

          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const data = this.state.data;
    if (this.state.loading) {
      return (
        <main id="top_page">
          {/* TOP セクション */}
          <section
            id="top_section"
            style={{ backgroundImage: `url(${topBgImage})` }}
          >
            <div className="top_content">
              <h1 className="title">
                Welcome to <br className="title_br" />
                Bar sunnyspot !!
              </h1>
              <h2 className="description">
                日常にバーを。日常の中でバーでの付加価値を。
              </h2>
            </div>
          </section>

          {/* About セクション */}
          <section id="about_section">
            <div className="about_section_wrapper">
              <div className="title_container">
                <h2 className="title">About</h2>
              </div>
              <div className="about_container">
                <div className="left">
                  <img src="/images/top-bg.jpg" alt="Bar sunnyspot" />
                </div>
                <div className="right">
                  <div className="description">
                    <p>
                      <b>
                        日常にバーを。
                        <br />
                        日常の中でバーでの付加価値を。
                        <br />
                        誰もが入りやすい敷居の低い創作カクテルバー。
                      </b>
                    </p>
                    <p className="sub">
                      当店はこちらのコンセプトのもと、
                      <br />
                      日々思考を凝らした創作カクテルや
                      <br />
                      選りすぐりのお酒をご提供させて頂いております。
                    </p>
                    <p className="sub">
                      人から人にご提供するからこそ出来る
                      <br />
                      手間を惜しまないサービスや商品を大事にし、
                      <br />
                      愛されるお店づくりを目指しております。
                    </p>
                    <p className="sub">
                      日々時代と共に進化しバーを通して、
                      <br />
                      皆様の日常に付加価値をご提供できるような
                      <br />
                      愛されるお店づくりを目指しております。
                    </p>
                  </div>
                </div>
              </div>
              <div className="reserve_btn_container">
                <a
                  className="reserve_btn"
                  href="https://lin.ee/8A9IajDo"
                  target="_blank"
                >
                  LINEで予約
                </a>
              </div>
            </div>
          </section>

          {/* Drink セクション */}
          <section id="drink_section">
            <div className="drink_section_wrapper">
              <div className="title_container">
                <h2 className="title">Drink</h2>
              </div>
              <div className="drinks_container">
                <a
                  href="https://www.instagram.com/p/B9Yn6xBBpBn/"
                  className="drink"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="drink_image_container">
                    <img src="/images/drink_1.jpeg" alt="カクテルの説明①" />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/p/CaqrFxDvZ4i/"
                  className="drink"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="drink_image_container">
                    <img src="/images/drink_2.jpeg" alt="カクテルの説明②" />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/p/Cc92ZWHPRG1/"
                  className="drink"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="drink_image_container">
                    <img src="/images/drink_3.jpeg" alt="カクテルの説明③" />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/p/CcpKT-QvDu3/"
                  className="drink"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="drink_image_container">
                    <img src="/images/drink_4.jpeg" alt="カクテルの説明④" />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/p/CjKn66Mv5oS/"
                  className="drink"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="drink_image_container">
                    <img src="/images/drink_5.jpeg" alt="カクテルの説明⑤" />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/p/CB0ArKwAWG5/"
                  className="drink"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="drink_image_container">
                    <img src="/images/drink_6.jpeg" alt="カクテルの説明⑥" />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/p/CNEq9ihA8_e/"
                  className="drink"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="drink_image_container">
                    <img src="/images/drink_7.jpeg" alt="カクテルの説明⑦" />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/p/CQVmdahAATE/"
                  className="drink sp-none"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="drink_image_container">
                    <img src="/images/drink_8.jpeg" alt="カクテルの説明⑧" />
                  </div>
                </a>
              </div>
              <div className="more_drinks_wrapper">
                <a
                  href="https://www.instagram.com/bar.sunnyspot/"
                  className="more_drinks"
                >
                  More
                </a>
              </div>
            </div>
          </section>

          {/* Information セクション */}
          <section id="information_section">
            <div className="information_section_wrapper">
              <div className="title_container">
                <h2 className="title">Information</h2>
              </div>
              <div className="blog_list_wrapper">
                <div className="blog_lists">
                  {data.slice(0, 4).map((value, i) => (
                    <Link
                      to={"/post/" + value.id}
                      key={i}
                      className="blog_container"
                    >
                      <div className="thumbnail_container">
                        <img
                          className="thumbnail"
                          src={
                            value.thumbnail_url
                              ? value.thumbnail_url
                              : `/images/no-thumbnail-${(i % 10) + 1}.jpg`
                          }
                          alt={value.title.rendered}
                        />
                      </div>
                      <div className="description_container">
                        <h2 className="title">{value.title.rendered}</h2>
                        <p className="date">
                          {value.date
                            .substring(0, value.date.indexOf("T"))
                            .replace(/-/g, "/")}
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
                <div className="more_blogs_wrapper">
                  <Link to="/information" className="more_blogs">
                    More
                  </Link>
                </div>
              </div>
            </div>
          </section>

          {/* Welcome セクション */}
          <section id="time_section">
            <div className="time_section_wrapper">
              <div className="title_container">
                <h2 className="title">Welcome</h2>
              </div>
              <div className="time_container">
                <div className="left">
                  <img
                    src="/images/welcome.jpg"
                    alt="Bar sunnyspot の営業時間"
                  />
                </div>
                <div className="right">
                  <RenderPost>
                    {this.state.informationData.content.rendered}
                  </RenderPost>
                  <div className="reserve_btn_container">
                    <a
                      className="reserve_btn"
                      href="https://lin.ee/xdEs6hD"
                      target="_blank"
                    >
                      LINEで予約
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Access セクション */}
          <section id="access_section">
            <div className="access_section_wrapper">
              <div className="title_container">
                <h2 className="title">Access</h2>
              </div>
              <div className="access_container">
                <div className="left">
                  <div className="map_container">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3233.4953386354277!2d139.9756950149004!3d35.86137628015267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60189d200b1f50dd%3A0x4144b21e5d68170f!2sBar%20sunnyspot!5e0!3m2!1sja!2sjp!4v1629082195138!5m2!1sja!2sjp"
                      width="600"
                      height="450"
                      style={{ border: "0", width: "100%" }}
                      allowFullScreen=""
                      loading="lazy"
                      title="Bar sunnyspot のGoogleマップ"
                    ></iframe>
                  </div>
                </div>
                <div className="right">
                  <div className="address_container">
                    <p className="address">
                      〒277-0011
                      <br />
                      千葉県柏市東上町7-13-2F
                    </p>
                    <p className="train">
                      ＪＲ常磐線 柏駅 徒歩8分
                      <br />
                      東武野田線（東武アーバンパークライン） 柏駅 徒歩8分
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* SNS セクション */}
          <section
            id="sns_section"
            style={{ backgroundImage: `url(${snsBgImage})` }}
          >
            <div className="sns_section_wrapper">
              <div className="left">
                <p className="title">Get Information and Follow Me!!</p>
                <p className="description">
                  インスタグラムで当店の最新情報をご確認いただけます。
                </p>
              </div>
              <div className="right">
                <a
                  className="instagram_btn"
                  href="https://www.instagram.com/bar.sunnyspot"
                >
                  Instagram
                  <img src="/images/instagram.svg" alt="Bar sunnyspot" />
                </a>
              </div>
            </div>
          </section>
        </main>
      );
    } else {
      return (
        <Loading />
      );
    }
  }
}

export default Top;
