import React from 'react';

import '../stylesheets/components/loading.scss';

class Loading extends React.Component {
  render() {
    return (
      <div id="loading">
        <div className="loading_wrapper">
          <p>Loading...</p>
        </div>
      </div>
    );
  }
}

export default Loading;
