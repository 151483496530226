import React from 'react';

import '../stylesheets/pages/coming_soon.scss';

// Coming Soonページ
class ComingSoon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
    };
  }

  render() {
    return (
      <div id="coming_soon_page">
        <div className="coming_soon_wrapper">
          <h2>Coming Soon ...</h2>
          <div className="coming_soon_img_container">
            <img src="/images/coming_soon.png" alt="Bar sunnyspot" />
          </div>
        </div>
      </div>
    );
  }
}

export default ComingSoon;
