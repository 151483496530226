import React from 'react';

import '../stylesheets/components/render_post.scss';

class RenderPost extends React.Component {
  render() {
    return (
      <article dangerouslySetInnerHTML={{__html: this.props.children}} />
    );
  }
}

export default RenderPost;
