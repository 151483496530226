import React from 'react';
import { privacy_policy_page_url } from '../config'

import '../stylesheets/pages/privacy_policy.scss';

import RenderPost from '../components/RenderPost'
import Loading from '../components/Loading'

// PrivacyPolicyページ
class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
    };
  }

  componentDidMount() {
    // Promise でデータを取得
    fetch(privacy_policy_page_url)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          loading: true,
          data: responseJson,
        });

      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const data = this.state.data;
    if (this.state.loading) {
      return (
        <div id="main">
          <div id="privacy_policy_page">
            <div className='privacy_policy_page_wrapper'>
              <div className='privacy_policy_container'>
                <h2>{data.title.rendered}</h2>
                <RenderPost>{data.content.rendered}</RenderPost>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <Loading />
      );
    }
  }
}

export default PrivacyPolicy;
