import React from 'react';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { post_url } from '../config'

import '../stylesheets/pages/post.scss';

import RenderPost from '../components/RenderPost'
import Loading from '../components/Loading'

//個別記事ページ用コンポーネント（タブ機能つき）
const Post = () => {
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    // Promise でデータを取得
    fetch(post_url + params.id + '?_embed')
      .then((response) => response.json())
      .then(async (responseJson) => {
        await setLoading(true);
        await setData(responseJson);

      })
      .catch((error) => {
        console.error(error);
      });
    }, []);

  useEffect(() => {
    // タイトル・ディスクリプション
    if (data.title) {
      document.title = `${data.title.rendered} | Bar sunnyspot`;
      document.getElementsByName(
        "description"
      )[0].content = `Bar sunnyspot の記事『${data.title.rendered}』`;
    }
  }, [data]);

  if (loading) {
    return (
      <div id="main">
        <div id="post_page">
          <div className='post_page_wrapper'>
            <div className='post_container'>
              <h2 className='title'>{data.title.rendered}</h2>
              <p className='date'>公開日：{data.date.substring(0, data.date.indexOf("T")).replace(/-/g, '/')}</p>
              <RenderPost>{data.content.rendered}</RenderPost>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <Loading />;
  }
}

export default Post;
// class Post extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       loading: false,
//       data: {},
//     }
//   }

//   componentDidMount() {
//     // Promise でデータを取得
//     fetch(post_url + this.props.match.params.id + '?_embed')
//       .then((response) => response.json())
//       .then(async (responseJson) => {
//         await this.setState({
//           loading: true,
//           data: responseJson,
//         });
//         // タイトル・ディスクリプション
//         document.title = `${this.state.data.title.rendered} | Bar sunnyspot`;
//         document.getElementsByName('description')[0].content = `Bar sunnyspot の記事『${this.state.data.title.rendered}』`;
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }

//   render() {
//     const data = this.state.data;
//     if (this.state.loading) {
//       return (
//         <div id="main">
//           <div id="post_page">
//             <div className='post_page_wrapper'>
//               <div className='post_container'>
//                 <h2 className='title'>{data.title.rendered}</h2>
//                 <p className='date'>公開日：{data.date.substring(0, data.date.indexOf("T")).replace(/-/g, '/')}</p>
//                 <RenderPost>{data.content.rendered}</RenderPost>
//               </div>
//             </div>
//           </div>
//         </div>
//       );
//     } else {
//       return (
//         <Loading />
//       );
//     }
//   }
// }

// export default withRouter(Post);
