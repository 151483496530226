import React from 'react';
import { Link } from 'react-router-dom';

import SpMenu from './SpMenu'

import '../stylesheets/components/header.scss';

// ヘッダー
class Header extends React.Component {
  render() {
    return (
      <header>
        <div className="wrapper">
          <h1 className="logo_wrapper">
            <Link to="/">
              <img
                src="/images/logo.png"
                alt="Bar sunnyspot"
                className="logo"
              />
            </Link>
          </h1>
          <div id="pc_menu">
            <ul className="links">
              <li>
                <Link to="/">HOME</Link>
              </li>
              {/* <li><Link to='/about/'>ABOUT</Link></li> */}
              <li>
                <a href="/#about_section">ABOUT</a>
              </li>
              {/* <li><Link to='/menu/'>MENU</Link></li> */}
              <li>
                <a href="/#drink_section">MENU</a>
              </li>
              <li>
                <Link to="/information/">INFORMATION</Link>
              </li>
            </ul>
            <ul className="sns">
              <li className="instagram">
                <a
                  href="https://www.instagram.com/bar.sunnyspot"
                  target="_blank"
                >
                  <img src="/images/instagram.svg" alt="Bar sunnyspot" />
                </a>
              </li>
              <li className="line">
                <a href="https://lin.ee/xKHP2Nb" target="_blank">
                  <img src="/images/line.png" alt="Bar sunnyspot" />
                </a>
              </li>
            </ul>
          </div>
          <div id="sp_menu">
            <SpMenu />
          </div>
        </div>
      </header>
    );
  }
}

export default  Header;
