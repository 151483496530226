import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Stylesheets
import './stylesheets/index.scss';

// Components
import Header from './components/Header'
import Footer from './components/Footer'
import ReserveButton from "./components/ReserveButton";

// Pages
import Top from './pages/Top'
// import About from './pages/About'
import Menu from './pages/Menu'
import Post from './pages/Post'
import Information from './pages/Information'
import ComingSoon from './pages/ComingSoon'
import PrivacyPolicy from './pages/PrivacyPolicy'
import NotFound from "./pages/NotFound";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Header />
    <Routes>
      <Route path={`/`} element={<Top />} />
      <Route path={`/about`} element={<ComingSoon />} />
      <Route path={`/menu`} element={<Menu />} />
      <Route path={`/blog`} element={<ComingSoon />} />
      <Route path={`/information`} element={<Information />} />
      <Route path={`/privacy-policy`} element={<PrivacyPolicy />} />
      <Route path={`/post/:id`} element={<Post />} />
      <Route path={`/*`} element={<NotFound />} />
    </Routes>
    <Footer />
    <ReserveButton />
  </BrowserRouter>
);
