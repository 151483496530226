import React from 'react';
import { Link } from 'react-router-dom';

import '../stylesheets/components/footer.scss';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="wrapper">
          <div className="logo_wrapper">
            <img src="/images/logo.png" alt="Bar sunnyspot" />
          </div>
          <p className="address">
            〒277-0011
            <br />
            千葉県柏市東上町7-13-2F
          </p>
          <ul className="sns">
            <li className="instagram">
              <a href="https://www.instagram.com/bar.sunnyspot" target="_blank">
                <img src="/images/instagram.svg" alt="Bar sunnyspot" />
              </a>
            </li>
            <li className="line">
              <a href="https://lin.ee/ge1O26L" target="_blank">
                <img src="/images/line.png" alt="Bar sunnyspot" />
              </a>
            </li>
          </ul>
        </div>
        <p className="privacy_policy_link">
          <Link to="privacy-policy">Privacy Policy</Link>
        </p>
        <p className="catch_copy">© 2021 Bar sunnyspot</p>
      </footer>
    );
  }
}

export default Footer;
