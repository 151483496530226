import React from 'react';
import { menu_page_url } from '../config'

import '../stylesheets/pages/post.scss';

import RenderPost from '../components/RenderPost'
import Loading from '../components/Loading'

//個別記事ページ用コンポーネント（タブ機能つき）
class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
    }
  }

  componentDidMount() {
    // タイトル・ディスクリプション
    document.title = 'メニュー | Bar sunnyspot';
    document.getElementsByName('description')[0].content = 'こだわりのカクテルをご用意しております';

    // Promise でデータを取得
    fetch(menu_page_url)
      .then((response) => response.json())
      .then(async (responseJson) => {
        await this.setState({
          loading: true,
          data: responseJson,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const data = this.state.data;
    if (this.state.loading) {
      return (
        <div id="main">
          <div id="post_page">
            <div className='post_page_wrapper'>
              <div className='post_container'>
                <h2 className='title'>{data.title.rendered}</h2>
                <p className='date'>公開日：{data.date.substring(0, data.date.indexOf("T")).replace(/-/g, '/')}</p>
                <RenderPost>{data.content.rendered}</RenderPost>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <Loading />
      );
    }
  }
}

export default Menu;
