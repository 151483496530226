import React from 'react';

import "../stylesheets/components/reserve_button.scss";

class ReserveButton extends React.Component {
  render() {
    return (
      <div id="reserve_button_container">
        <a
          className="reserve_btn"
          href="https://lin.ee/b9mWqsk"
          target="_blank"
        >
          <img
            src="/images/logo-icon.png"
            alt="Bar sunnyspot"
            className="logo"
          />
          <span className="letter">LINEで予約</span>
        </a>
      </div>
    );
  }
}

export default ReserveButton;
