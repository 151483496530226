import React from 'react';
import { Link } from 'react-router-dom'

import '../stylesheets/components/sp_menu.scss';

// スマホ時メニュー
class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      isShow: !this.state.isShow
    });
  }

  render() {
    return (
      <div id="sp_menu">
        <span
          className={"menu-trigger" + (this.state.isShow ? " active" : "")}
          onClick={this.toggle}
        >
          <span></span>
          <span></span>
          <span></span>
        </span>
        <div
          className={"sp_menu_overlap" + (this.state.isShow ? " active" : "")}
        >
          <span className="menu-close-trigger" onClick={this.toggle}>
            <img src="/images/close.svg" alt="Bar sunnyspot" />
          </span>
          <Link to="/" className="sp_menu_link" onClick={this.toggle}>
            HOME
          </Link>
          {/* <Link to='/about/' className="sp_menu_link" onClick={this.toggle}>About</Link> */}
          <a
            href="/#about_section"
            className="sp_menu_link"
            onClick={this.toggle}
          >
            ABOUT
          </a>
          {/* <Link to='/menu/' className="sp_menu_link" onClick={this.toggle}>MENE</Link> */}
          <a
            href="/#drink_section"
            className="sp_menu_link"
            onClick={this.toggle}
          >
            MENU
          </a>
          <Link
            to="/information/"
            className="sp_menu_link"
            onClick={this.toggle}
          >
            INFORMATION
          </Link>
          <div className="reserve_btn_container">
            <a
              className="reserve_btn"
              href="https://lin.ee/3kMvmZ2"
              target="_blank"
            >
              LINEで予約
            </a>
          </div>
          <div className="sp_menu_footer">
            <div className="logo_wrapper">
              <img src="/images/logo.png" alt="Bar sunnyspot" />
            </div>
            <ul className="sns">
              <li className="instagram">
                <a
                  href="https://www.instagram.com/bar.sunnyspot"
                  target="_blank"
                >
                  <img src="/images/instagram.svg" alt="Bar sunnyspot" />
                </a>
              </li>
              <li className="line">
                <a href="https://lin.ee/0egBsFG" target="_blank">
                  <img src="/images/line.png" alt="Bar sunnyspot" />
                </a>
              </li>
            </ul>
            <p className="address">千葉県柏市東上町7-13-2F</p>
            <p className="open_hours">Open：15:00-20:00 / L.O.19:00</p>
          </div>
        </div>
      </div>
    );
  }
}


export default Menu;
