import React from 'react';
import { Link } from 'react-router-dom'
import { posts_url } from '../config'

import '../stylesheets/pages/information.scss';

import Loading from '../components/Loading'

// Informationページ
class Information extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
    };
  }

  componentDidMount() {
    // タイトル・ディスクリプション
    document.title = 'お知らせ一覧 | Bar sunnyspot';
    document.getElementsByName('description')[0].content = 'Bar sunnyspot のお知らせ一覧';

    // Promise でデータを取得
    fetch(posts_url + '?orderby=created&order=desc&per_page=100&page=1')
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          loading: true,
          data: responseJson,
        });

      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const data = this.state.data;
    if (this.state.loading) {
      return (
        <div id="main">
          <div id="information_page">
              <div className='blog_list_wrapper'>
                <h2>お知らせ一覧</h2>
                <div className='blog_lists'>
                  {data.map((value, i) => (
                    <Link to={'/post/' + value.id} key={i} className='blog_container'>
                      <div className='thumbnail_container'>
                        <img className='thumbnail' src={value.thumbnail_url ? value.thumbnail_url : `/images/no-thumbnail-${i % 10 + 1}.jpg`} alt={value.title.rendered} />
                      </div>
                      <div className='description_container'>
                        <h2 className='title'>{value.title.rendered}</h2>
                        <p className='date'>{value.date.substring(0, value.date.indexOf("T")).replace(/-/g, '/')}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
          </div>
        </div>
      );
    } else {
      return (
        <Loading />
      );
    }
  }
}

export default Information;
